export const words_data = [
    { text: "company", value: 194218 },
    { text: "services", value: 183712 },
    { text: "business", value: 138437 },
    { text: "solutions", value: 119200 },
    { text: "technology", value: 116756 },
    { text: "products", value: 113251 },
    { text: "management", value: 97032 },
    { text: "provides", value: 90142 },
    { text: "service", value: 89777 },
    { text: "online", value: 89173 },
    { text: "new", value: 89038 },
    { text: "platform", value: 88332 },
    { text: "development", value: 87816 },
    { text: "based", value: 84921 },
    { text: "software", value: 84014 },
    { text: "companies", value: 81234 },
    { text: "also", value: 79693 },
    { text: "offers", value: 75022 },
    { text: "data", value: 75010 },
    { text: "customers", value: 74045 },
    { text: "mobile", value: 69285 },
    { text: "founded", value: 66133 },
    { text: "clients", value: 66042 },
    { text: "provide", value: 65415 },
    { text: "one", value: 64343 },
    { text: "industry", value: 63652 },
    { text: "experience", value: 63304 },
    { text: "marketing", value: 61569 },
    { text: "help", value: 61480 },
    { text: "users", value: 58085 }
  ];