export const bar_data = [
  { name: 'Administrative Services', value: 83 },
  { name: 'Advertising', value: 62 },
  { name: 'Agriculture and Farming', value: 503 },
  { name: 'Apps', value: 123 },
  { name: 'Artificial Intelligence', value: 220 },
  { name: 'Biotechnology', value: 402 },
  { name: 'Clothing and Apparel', value: 127 },
  { name: 'Commerce and Shopping', value: 557 },
  { name: 'Community and Lifestyle', value: 291 },
  { name: 'Consumer Electronics', value: 997 },
  { name: 'Consumer Goods', value: 359 },
  { name: 'Content and Publishing', value: 105 },
  { name: 'Data and Analytics', value: 501 },
  { name: 'Design', value: 296 },
  { name: 'Education', value: 151 },
  { name: 'Energy', value: 9942 },
  { name: 'Events', value: 21 },
  { name: 'Financial Services', value: 636 },
  { name: 'Food and Beverage', value: 483 },
  { name: 'Gaming', value: 26 },
  { name: 'Government and Military', value: 138 },
  { name: 'Hardware', value: 1509 },
  { name: 'Health Care', value: 500 },
  { name: 'Information Technology', value: 1024 },
  { name: 'Internet Services', value: 633 },
  { name: 'Lending and Investments', value: 267 },
  { name: 'Manufacturing', value: 2092 },
  { name: 'Media and Entertainment', value: 239 },
  { name: 'Messaging and Telecommunications', value: 10 },
  { name: 'Mobile', value: 258 },
  { name: 'Music and Audio', value: 9 },
  { name: 'Natural Resources', value: 5086 },
  { name: 'Navigation and Mapping', value: 43 },
  { name: 'Payments', value: 42 },
  { name: 'Platforms', value: 33 },
  { name: 'Privacy and Security', value: 83 },
  { name: 'Professional Services', value: 554 },
  { name: 'Real Estate', value: 1021 },
  { name: 'Sales and Marketing', value: 202 },
  { name: 'Science and Engineering', value: 1894 },
  { name: 'Software', value: 1339 },
  { name: 'Sports', value: 108 },
  { name: 'Sustainability', value: 14674 },
  { name: 'Transportation', value: 741 },
  { name: 'Travel and Tourism', value: 112 },
  { name: 'Video', value: 22 }
]